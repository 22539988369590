<template>
  <div class="scanner-container" v-show="!isLoading">
    <video poster="data:image/gif,AAAA" ref="scanner"></video>

    <!-- Camera Selector -->
    <div class="camera-selector-container">
      <CameraSelector  v-if="currentCamera"
        :cameras="cameraArr" 
        :selectedCamera="currentCamera"
        @camera-change="onCameraChange"
      />
    </div>

    <div class="overlay-element"></div>
    <div class="laser"></div>
  </div>
</template>


<script>
import { BrowserMultiFormatReader, Exception } from "@zxing/library";
import CameraSelector from './CameraSelector.vue';

export default {
  name: "stream-barcode-reader",
  components: {
    CameraSelector
  },
  data() {
    return {
      isLoading: true,
      cameraArr: [],
      cameraIndex: 0,
      currentCamera: null,
      codeReader: new BrowserMultiFormatReader(),
      isMediaStreamAPISupported: navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices
    };
  },
  async mounted() {
    if (!this.isMediaStreamAPISupported) {
      throw new Exception("Media Stream API is not supported");
    }
    const devices = await navigator.mediaDevices.enumerateDevices();
    this.cameraArray = devices.filter(device => device.kind === 'videoinput');
  //alert(this.cameraArray.length);
    // if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    //   this.codeReader.enumerateDevices()
    //   .then((videoInputDevices) => {
    //       this.cameraArray = videoInputDevices;
    //       alert(videoInputDevices.length);
    //       let selectedDeviceId = videoInputDevices[0]?.deviceId;
    //       this.start(selectedDeviceId);
    //   })
    //   .catch((err) => {
    //       console.error(err)
    //   })
    // } else {
    // this.codeReader.listVideoInputDevices()
    // .then((videoInputDevices) => {
    // this.cameraArray = videoInputDevices;
    //get tư local storge
    var deviceIdDefault = localStorage.getItem("device_id_camera");
    //console.log("deviceIdDefault", deviceIdDefault)
    //alert(deviceIdDefault)
    this.cameraIndex =0;
    console.log("cameraArray", this.cameraArray)
     for (let i=0; i < this.cameraArray.length; i++) {
      let m = this.cameraArray[i];
      if(deviceIdDefault == m.deviceId){
        this.cameraIndex = i;
      }
       this.cameraArr.push({
         //label: m.label,
         label:i+1+'',
         groupId: m.groupId,
         deviceId: m.deviceId
       })
      //  this.cameraArr.push({
      //    //label: m.label,
      //    label:i+1+'',
      //    groupId: m.groupId,
      //    deviceId: m.deviceId
      //  })
     }
     localStorage.setItem("device_id_camera", this.cameraArr[this.cameraIndex]?.deviceId);
     //this.cameraIndex = this.cameraArray.length > 1 ? 1 : 0;
    this.currentCamera = (this.cameraArr.length) > 0 ? this.cameraArr[this.cameraIndex]:null;
    console.log("currentCamera", this.currentCamera);
     let selectedDeviceId = this.cameraArr[this.cameraIndex]?.deviceId;
     this.start(selectedDeviceId);
    // })
    // .catch((err) => {
    //     console.error(err)
    // })
    // }
    this.$refs.scanner.oncanplay = (event) => {
      console.log(event);
      this.isLoading = false;
      this.$emit("loaded");
    };
  },

  // async mounted() {
  //   if (!this.isMediaStreamAPISupported) {
  //     throw new Exception("Media Stream API is not supported");
  //   }

  //   try {
  //     // Request camera permissions with advanced constraints to access all cameras
  //     await navigator.mediaDevices.getUserMedia({
  //       video: {
  //         advanced: [
  //           { facingMode: 'environment' },
  //           { facingMode: 'user' },
  //           { focusMode: ['manual', 'continuous'] },
  //           { width: { min: 640, ideal: 1920 } },
  //           { height: { min: 400, ideal: 1080 } }
  //         ]
  //       }
  //     });

  //     // Enumerate all devices after getting permissions
  //     const devices = await navigator.mediaDevices.enumerateDevices();
  //     const cameras = devices.filter(device => device.kind === 'videoinput');
  //     console.log("cameras", cameras);

  //     // Get capabilities of each camera
  //     const cameraPromises = cameras.map(async camera => {
  //       try {
  //         const stream = await navigator.mediaDevices.getUserMedia({
  //           video: { deviceId: { exact: camera.deviceId } }
  //         });
  //         const track = stream.getVideoTracks()[0];
  //         const capabilities = track.getCapabilities();
  //         track.stop(); // Stop the test stream
          
  //         return {
  //           label: camera.label,
  //           groupId: camera.groupId,
  //           deviceId: camera.deviceId,
  //           capabilities
  //         };
  //       } catch (err) {
  //         console.warn(`Could not get capabilities for camera: ${camera.label}`, err);
  //         return {
  //           label: camera.label,
  //           groupId: camera.groupId,
  //           deviceId: camera.deviceId,
  //           capabilities: {}
  //         };
  //       }
  //     });

  //     this.cameraArr = await Promise.all(cameraPromises);
  //     console.log('Available cameras:', this.cameraArr);

  //     // Try to select back camera first
  //     const backCamera = this.cameraArr.find(camera => {
  //       const label = (camera.label || '').toLowerCase();
  //       return label.includes('back') || 
  //              label.includes('rear') || 
  //              label.includes('penta') || 
  //              label.includes('ultra') || 
  //              label.includes('wide');
  //     });

  //     this.currentCamera = backCamera || this.cameraArr[0];
  //     console.log("currentCamera", this.currentCamera);
  //     this.start(this.currentCamera.deviceId);
  //     this.$refs.scanner.oncanplay = () => {
  //     this.isLoading = false;
  //     console.log("oncanplay")
  //     this.$emit("loaded");
  //   };
  //   } catch (err) {
  //     console.error('Error initializing cameras:', err);
  //     throw new Exception("Could not initialize cameras: " + err.message);
  //   }
    
    
  // },
  beforeDestroy() {
    console.log("beforeDestroy")
    this.$refs.scanner.onpause = () => {
      this.isLoading = true;
      this.$emit("loaded");
    };
    this.codeReader.reset();
  },
  methods: {
    onCameraChange(camera) {
      if (this.cameraArr.length <= 1)
       return;
      this.currentCamera = camera;
      localStorage.setItem("device_id_camera", camera.deviceId);
      //console.log("camera", camera);
      //alert(camera.deviceId)
      this.codeReader.reset();
      this.start(camera.deviceId);
    },
    start(selectedDeviceId = undefined) {
      let videoConstraints = {
        width: { min: 640, ideal: 1920 },
        height: { min: 400, ideal: 1080 },
        advanced: [
          { focusMode: ['manual', 'continuous'] }
        ]
      };
      if (selectedDeviceId) {
        videoConstraints["deviceId"] = { exact: selectedDeviceId };
      }
      console.log("videoConstraints", videoConstraints);
      this.codeReader.decodeFromConstraints({ video: videoConstraints }, this.$refs.scanner, (result, err) => {
        console.log("result", result);
        console.log("err", err);
        if (result) {
          this.$emit("decode", result.text);
          this.$emit("result", result);
        }
      });
    }
  }
};
</script>

<style scoped>
video {
  max-width: 100%;
  max-height: 100%;
  position: relative;
  z-index: 0; /* Đảm bảo video không che mất zoom */
}

.scanner-container {
  position: relative;
  overflow: hidden;
}
.camera-selector-container {
  position: absolute;
  left: 5px;
  top: 32px;
  z-index: 10;
}

</style>
