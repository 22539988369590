<template>
  <div class="camera-selector">
    <div
      v-for="camera in cameras"
      :key="camera.deviceId"
      class="camera-option"
      :class="{ 'active': camera.deviceId === selectedCamera.deviceId }"
      @click="selectCamera(camera)"
      :title="camera.label"
    >
      {{ formatCameraName(camera.label) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cameras: {
      type: Array,
      required: true
    },
    selectedCamera: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectCamera(camera) {
      this.$emit("camera-change", camera);
    },
    formatCameraName(label) {
      if (!label) return 'Camera';
      
      // Xử lý tên camera phổ biến
      const normalizedLabel = label.toLowerCase();
      
      if (normalizedLabel.includes('back')) return 'back';
      if (normalizedLabel.includes('front')) return 'front';
      if (normalizedLabel.includes('penta')) return 'penta';
      if (normalizedLabel.includes('ultra')) return 'ultra';
      if (normalizedLabel.includes('wide')) return 'wide';
      if (normalizedLabel.includes('tele')) return 'tele';
      if (normalizedLabel.includes('macro')) return 'macro';
      if (normalizedLabel.includes('depth')) return 'depth';
      
      // Nếu không match với các tên phổ biến, trả về tên gốc
      return label;
    }
  }
};
</script>

<style scoped>
.camera-selector {
  display: flex;
  flex-direction: column; /* Xếp các camera theo chiều dọc */
  align-items: flex-start; /* Căn trái */
  gap: 10px; /* Khoảng cách giữa các camera */
  background: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  padding: 10px;
  max-height: 90vh; /* Giới hạn chiều cao */
  overflow-y: auto; /* Cuộn nếu quá nhiều camera */

  
}

.camera-option {
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 15px;
  transition: 0.3s ease-in-out;
  width: max-content; /* Kích thước theo nội dung */
  text-align: left; /* Căn chữ bên trái */
}

.camera-option.active {
  background: rgba(255, 215, 0, 0.8);
  color: black;
}

</style>
